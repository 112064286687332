.widget-highlights {
    padding: 30px 0;
    overflow: hidden;
    h2{
        font-size:18px;
    }

    .highlights-container {
        margin: 15px auto;
        position:relative;
        .arrow{
            @include arrow;
        }
        &.slick-slider {
            overflow: hidden;
        }

        .slick-list {
            margin: 0 -15px;
        }
        .vo-carousel-item{
            margin: 0 15px;
            figure{
                &>img{
                    margin-bottom: 15px;
                    width: 100%;
                }
                figcaption h3, .description{
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}