@import '../../partials/general';
@import '../../partials/mobile/general';
@import '../../partials/mobile/header';
@import '../../partials/mobile/footer';
@import '../../widgets/breadcrumbs/general';
@import '../../widgets/social/general';
@import '../../partials/general/actions';
@import '../../partials/general/actions_filter';
@import '../common/shopping_buttons';
@import 'general';

.slick-overflow-wrapper{
  .slick-list {
    margin-right: -15px;
  }
}

.showcase-container {
  .showcase {
      .products-showcase {
          padding-top: 30px;
          padding-bottom: 30px;
          .showcase-title{
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 30px;
          }
          .showcase-subtitle{
              font-size: 18px;
              line-height: 22px;
          }
          .slick-arrow {
              &.left{
                  left: 20px;
              }
              &.right{
                  right: 20px;
              }
          }
          &.showcase-6x{
              padding-top: 15px;
              padding-bottom: 30px;
          }
          &.showcase-3x, &.showcase-image-3, &.showcase-image-2{
              .products-list {
                margin-left: -30px;
                .slick-arrow {
                  top: 125px;
                  &.left{
                    left: 35px;
                  }
                }
              }
          }

          &.showcase-2x, &.showcase-image-2, &.showcase-video-2, &.showcase-image-1, &.showcase-1-1, &.showcase-video-1{
              .product-details {
                  text-align: center;
              }
          }
          &.showcase-video-2, &.showcase-video-1{
              .media-container{
                  height: 200px;
              }
          }
          &.showcase-video-3, &.showcase-video-2{
              .products-list {
                  .slick-arrow {
                      top: 105px;
                  }
              }
          }
          &.showcase-4x{
            .products-list {
              .slick-arrow {
                &.left{
                  left: 35px;
                }
                &.right{
                  right: 5px;
                }
              }
            }
          }
          &.showcase-2x {
            .btn-go-to-cart, .product-to-buy-btn, .checkout-button{
              width: 160px;
              left: 50%;
              margin-left: -80px;
            }
          }

          &.showcase-2x, &.showcase-image-2, &.showcase-image-1, &.showcase-1-1, &.showcase-video-1{
              .products-list {
                  .slick-arrow {
                      top: 22%;
                  }
                  .product {
                      .product-image {
                          .image-wrapper {
                              img {
                                  width: 160px;
                                  margin: 0 auto;
                                  display: block;
                              }
                          }
                      }
                      .product-details{
                          margin-top: 15px;
                          .product-title{
                              font-size: 18px;
                              line-height: 22px;
                          }
                        .product-price{
                            .with-discount{
                              font-size: 25px;
                              line-height: 29px;
                            }
                        }
                      }
                      .discount-percentage{
                          left: 50%;
                          margin-left: 50px;
                      }
                  }
              }
          }
          &.showcase-image-2{
            .products-list{
              .product{
                .discount-percentage{
                  right: -15px;
                }
              }
            }
          }
          &.slick-overflow-wrapper{
            .products-list{
              &.row{
                .slick-list{
                  margin-right: -30px;
                }
              }
            }
            .product {
              .product-image, .product-details {
                width: 160px;
              }
            }
          }
      }
  }
}

.featured-products-slider {
  .featured-products-wrapper {
    height: 170px;
    .featured-products {
      height: 170px;
      .featured-products-slider-arrow {
        left: 10px;
        right: 10px;
      }
      .featured-product {
        .picture-container {
          width: 100%;
          margin-left: -25px;
          // max-height: 215px;
          .picture {
            margin-left: 17px;
          }
        }
        .slider-banner {
          min-height: 215px;
          .details {
            height: 70px;
          }
        }
      }
    }
  }
  .top-details {
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    &.not-visible {
      margin: 0px;
      display: none !important;
    }
    .title {
      font-size: 18px;
      text-transform: uppercase;
      line-height: 22px;
    }
  }
  .slick-slider {
    .slick-dots {
      /*
      top: 145px;
      bottom: initial;
      */
    }
  }
}

.showcase-container {
  .showcase {
    .top-details {
      margin-top: 0px;
      padding: 0;
    }
    .products-showcase {
      .ver-mais-botao {
        /*margin-top: 20px;*/
        margin-top: 20px;
      }
    }
    &.news {
      .products-showcase{
        .slick-arrow {
          &.left{
            left: 10px;
          }
          &.right{
            right: 10px;
          }
        }
      }
    }
    .featured-products-slider {
      .slick-slider{
        .arrow {
          &.left {
            left: 10px;
          }
          &.right {
            right: 10px;
          }
        }
      }
    }
  }
  .featured-products-slider {
    padding-top: 30px;
    padding-bottom: 30px;
    .featured-products-wrapper {
      height: 170px;
      .featured-products {
        height: 170px;
        .featured-product {
          .picture-container {
            width: 100%;
            margin-left: -25px;
          }
          .slider-banner{
            min-height: 170px;
          }
          .details {
            height: 170px;
            .details-container {
              height: 170px;
            }
          }
        }
      }
    }
  }
}
